import React from 'react';

import { images } from '../../constants';
import { SubHeading } from '../../components';
import './Chef.css';

const Chef = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img-reverse"> 
      <img src={images.chef} alt="chef img" />
    </div>

    <div className="app__wrapper_info">
      <SubHeading title="Le Mot Du Chef" />
      <h1 className="headtext__cormorant">Nos Convictions</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote" />
          <p className="p__opensans">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aspernatur tenetur eos tempora rem beatae deserunt delectus magni.</p>
        </div>
        <p className="p__opensans">Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem aspernatur optio vero quos nemo nisi eaque accusantium error voluptas, at est, hic dolor dignissimos tempore animi quidem praesentium corrupti. Pariatur, tempora cum.</p>
      </div>

      <div className="app__chef-sign">
        <p>Kevin Luo</p>
        <p className="p__opensans"> Chef & Founder</p>
        <img src={images.sign} alt="sign" />
      </div>
    </div>
  </div>
);

export default Chef;
